//
// Custom checkbox
//

.custom-checkbox {
    .custom-control-input ~ .custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                }
                &::after {
                    // background-image: $custom-checkbox-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }
}

h1.headerTitle {
    color: black;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 26px;
  }