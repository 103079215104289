@mixin hide-scrollbar() {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

a {
  cursor: pointer;
}

.fa,
.fas {
  font-weight: 900 !important;
}

// Reboot
@media (max-width: 767.98px) {
  body {
    padding-top: 76px;
  }
}

// Grid
.g-2 {
  margin-top: -0.5rem;
  margin-right: -0.25rem;
  margin-left: -0.25rem;

  > * {
    margin-top: 0.5rem;
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
}

.g-3 {
  margin-top: -1rem;
  margin-right: -0.5rem;
  margin-left: -0.5rem;

  > * {
    margin-top: 1rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.g-4 {
  margin-top: -1.5rem;
  margin-right: -0.75rem;
  margin-left: -0.75rem;

  > * {
    margin-top: 1.5rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

// Table
.table {
  margin-bottom: 0;
  border: $table-border-width solid $table-border-color;
  border-radius: 0;

  td,
  th {
    padding: 0.5rem;
  }

  > :first-child > :first-child {
    > :first-child {
      border-top-left-radius: inherit;
    }

    > :last-child {
      border-top-right-radius: inherit;
    }
  }

  > :last-child > :last-child {
    > :first-child {
      border-bottom-left-radius: inherit;
    }

    > :last-child {
      border-bottom-right-radius: inherit;
    }
  }

  thead {
    th {
      .material-icons-outlined {
        position: relative;
        top: 2px;
        font-size: 14px;
      }
    }
  }

  td,
  th {
    .material-icons-outlined,
    i {
      font-size: 16px;
    }
  }
}

.table-responsive {
  margin-bottom: 1rem;
}

.not-default .table-responsive-y {
  max-height: 650px;
}

.table-responsive-y {
  max-height: 350px;
  overflow-y: auto;

  .infinite-scroll-component {
    height: initial !important;
    overflow: initial !important;
  }

  table {
    position: relative;
    border: 0;
  }

  thead tr th {
    position: sticky;
    top: -1px;
    z-index: 5;
  }

  .table-width thead tr th {
    min-width: 130px;
  }

  .table-width thead tr th:first-child {
    min-width: 250px;
  }

  .table-width tbody tr td:nth-child(5) {
    min-width: 192px;
  }

  tfoot tr td {
    // position: sticky;
    // bottom: -1px;
    z-index: 5;
    background-color: $white;
  }
}
.overflow_auto_content .infinite-scroll-component {
  overflow: initial !important;
  // max-height: auto !important;
}

// Form
.text-error {
  font-size: 80%;
  color: $danger;
}

.text-word {
  font-size: 80%;
  position: relative;
  margin-top: 5px;
  line-height: 17px;
  display: block;
}

.text-word.position-relative {
  bottom: 0;
}
.custom-control-inline-account {
  margin-bottom: 18px !important;
  display: flex;
  align-items: flex-end;
}

.input-group-prepend {
  .input-group-text:last-child {
    border-right: 0;
  }
}

.input-group-append {
  .input-group-text:first-child {
    border-left: 0;
  }
}

.input-group-text {
  .material-icons-outlined {
    font-size: 18px;
  }
}

// Dropdown
[aria-expanded="true"] .chevron-collapse {
  transform: rotate(180deg);
}

// Modal
.modal-screen {
  max-width: calc(100vw - 50px);
}

.modal-header .close {
  padding: 1rem;
}

// Sidebar
.navbar-vertical {
  .navbar-nav .nav-link > .chevron-collapse {
    min-width: auto;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    &.navbar-hidden.fixed-left {
      transform: translateX(-100%);

      + .main-content {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .navbar-vertical.navbar-expand-md {
    height: 76px;

    .navbar-collapse {
      height: 90vh !important;
    }
  }
}

.btn-default.btn_small {
  min-height: 32px;
  font-size: 0.775rem !important;
  padding: 0.3rem 0.75rem;
  line-height: 1;
}
.btn-default.btn_small i {
  font-size: 16px;
}

// Navbar
.navbar-toggler {
  padding: 0;
  font-size: 28px;
  color: #fff !important;
}

.main-content .navbar-toggler-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 10px;
}

// Nav
.nav-scrollable {
  white-space: nowrap;
  flex-wrap: nowrap;
  // overflow-x: auto;
  padding-bottom: 4px;
  margin-right: 20px;

  @include hide-scrollbar();
}

// Card
.card {
  box-shadow: $box-shadow-sm;

  .table {
    border: 0;
  }
}

.tade_table_height {
  min-height: 430px;
}

// .tade_table_height_double .table-responsive-y {
//   max-height: 800px;
// }

.card-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 0;
}

.table_dark {
  background-color: #000;
  color: #fff;
}

.table_dark tr {
  background-color: #000;
  color: #fff;
}
.table_dark tr#active-selected {
  background-color: gray !important;
  color: #000;
}

.table_dark tr td,
.table_dark tr th {
  font-size: 18px;
  border-top: 1px solid #343434;
}
// Buttons
.btn {
  min-height: 39px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  > span {
    display: inline-flex;
    line-height: 1;
  }

  .material-icons-outlined {
    font-size: 18px;
  }

  .btn-inner--icon:only-child {
    margin-right: 0;
  }
}

.pagination {
  margin-bottom: 0;
}

// Nav link
.nav-link-icon i {
  font-size: 24px;
}

.nav-pills {
  .nav-link {
    border: 1px solid transparent;
  }
}

// Icons
.material-icons,
.material-icons-outlined {
  font-size: inherit;
}

// Badge
.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &-float {
    position: absolute;
    top: 0;
    right: 0;
  }
}

// Loader
.spinner-box {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: #885b242b;
  display: flex;
  z-index: 5555;
  position: fixed;
  top: 0;
  left: 0;
}

// Helpers
.hide-scrollbar {
  @include hide-scrollbar();
}

// Utilities

.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}

// keyur
.react-datepicker-popper {
  z-index: 99 !important;
}
.downloadApkClass {
  color: #172b4d;
  background: white;
  border: 1px solid #172b4d;
  border-radius: 10px;
  padding: 8px 15px;
  transition: 0.4s;
}
.downloadApkClass:hover {
  background-color: #885b24;
  border-color: #885b24;
  color: #fff;
  transition: 0.4s;
}

.settings-button {
  margin-top: 31px;
}
.notification-button {
  margin-top: 31px;
}

.modal-header {
  color: $white;
  align-items: center;
  background-color: #8898aa;
}

.modal-footer {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-top: 1px solid rgba($black, 0.1);
}

.modal-title {
  font-size: 1.125rem;
  color: inherit;
}

.close {
  opacity: 1;
  color: $white;
}

.close > span:not(.sr-only) {
  color: $white;
}

.btn-default:hover,
.btn-default:focus {
  background-color: $primary;
  border-color: $primary;
}

.custom-search {
  border: 1px solid #cad1d7;
  border-left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  width: calc(100% - 43px);
}

.custom-search::placeholder {
  color: #adb5bd;
}

.colSpanFooter {
  column-span: 2;
}

.sidebar-nav-transform > li > a.active:hover {
  transform: scale(1.05) !important;
}

#sidenav-main.navbar-light .navbar-nav .nav-link.active {
  color: #fff;
}
#sidenav-main.navbar-light .navbar-nav .nav-link.active.current {
  color: #fff;
}
#sidenav-main.navbar-light .navbar-nav .nav-link:hover {
  color: #000;
}

.sidebar-nav-transform > li:hover {
  transform: scale(1.005);
}

.spinreverse:hover {
  -webkit-animation: spinreverses 1s linear;
}

@-webkit-keyframes spinreverses {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

.top-header {
  background-color: $default;
  position: fixed;
  width: calc(100% - 250px);
  top: 0;
  left: 250px;
  z-index: 9;
}
.top-header.nav-close {
  width: 100%;
  left: 0;
}

.ledger_title {
  min-width: 130px;
}

.custome_input_wrap {
  min-width: 220px;
}

.custome_radio_wrap {
  min-width: 120px;
}

.trade-save-button {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.radio-brokerage .custom-control-inline {
  margin-right: 12px;
}
.marquee_mobile {
  display: none;
}

@media (max-width: 1600px) {
  .settings-button {
    margin-top: 0;
  }
  .custom-control-inline-account {
    justify-content: center;
  }
}

.input-group {
  border: 1px solid transparent;
}
.focusActive {
  border: 1px solid #4b5bb6;
}
.form-control:focus {
  background-color: #e1ebff;
}
.focusActive .form-control {
  border-color: transparent;
  background-color: #e1ebff;
}

.focusActive .input-group-text {
  border-color: transparent;
  background-color: #e1ebff;
}

.focusActive .custom-search {
  border-color: transparent;
  background-color: #e1ebff;
}

.text-table-disable {
  color: #fff;
  opacity: 0.5;
}

.text-table-enable {
  color: #ffffff;
}
.table .thead-light th > span {
  display: inline-block;
}
.table .thead-light th > span:first-child {
  margin-left: 10px;
}
.table .thead-light th > span .material-symbols-outlined {
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  line-height: 17px;
  margin-left: -4px;
}

.text-success {
  color: #0aab66 !important;
}

@media (max-width: 767px) {
  .main-content {
    margin-top: 0;
  }
  .main_web_mobile {
    margin-top: 96px;
  }

  .navbar-vertical.navbar-expand-md {
    height: 96px;
  }

  .navbar-vertical.navbar-expand-md .row {
    display: contents;
    justify-content: space-between;
  }

  .navbar-vertical.navbar-expand-md .row marquee {
    margin-top: 10px;
  }
  .top_dashboard_wrap {
    margin-top: 10px;
    flex-wrap: wrap;
  }
  .marquee_mobile {
    display: block;
  }
  .text-muted small {
    color: #885b24;
    font-size: 15px;
    font-weight: 500;
  }
}

@media (max-width: 575px) {
  .top_dashboard_right .card {
    margin-top: 10px;
  }
}

.table-auto {
  max-height: auto !important;
}

textarea.form-control {
  height: 200px;
}

.wrapp_table tbody tr td:nth-child(2) {
  white-space: break-spaces;
}

/* @media (max-width: 500px) {
  .navbar-vertical.navbar-expand-md .navbar-collapse {
    height: 100vh !important;
    margin: 0;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    max-width: 310px;
    padding: 15px;
  }

  .navbar-vertical.navbar-expand-md .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .navbar-collapse .navbar-collapse-header .collapse-brand h1 {
    font-size: 18px;
  }
} */

input[type="password"] {
  -webkit-text-security: none;
}

.accountCode {
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
}
.accountCode option {
  padding: 10px;
}

.minimize_icon {
  font-size: 16px;
  transform: rotateX(180deg);
}

.top_dashboard_wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.top_dashboard_right .card {
  min-width: 130px;
  // margin-left: 20px;
}
.top_dashboard_right .card .card-body {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top_dashboard_right .card .card-body h1 {
  margin-bottom: 0;
  margin-right: 10px;
  line-height: 25px;
  font-size: 21px;
}

.input_wrapper_col {
  position: relative;
}
.input_wrapper_col .text-error {
  position: absolute;
  bottom: -19px;
  left: 0;
}

.input_wrapper_col .text-error.position-relative {
  bottom: 0 !important;
}
.mcxtable_wrap table {
  width: 100%;
}

.mcxtable_wrap table th,
.mcxtable_wrap table td {
  padding: 7px;
  white-space: nowrap;
}

.mcxtable_wrap table td {
  background-color: #000;
  border-bottom: 1px solid #3a3b3c;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
}

.mcxtable_wrap table tr.active-selected-class td {
  background-color: gray;
  color: #000;
}
.mcxtable_wrap table tr.today-high td {
  background-color: #0000cd;
  color: #fff;
}
.mcxtable_wrap table tr.today-low td {
  background-color: #bf2114;
  color: #fff;
}

.mcxtable_wrap table td:last-child {
  text-align: center;
}

.mcxtable_wrap table thead th {
  border-bottom: 1px solid #fff;
  background-color: $default;
  color: #fff;
}

.pagination_wrap {
  padding-bottom: 10px;
  overflow: auto;
}

.pagination_wrap ul {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  justify-content: flex-end;
  flex-wrap: nowrap;
  @media (max-width: 991px) {
    justify-content: flex-start;
  }
}

.pagination_wrap ul li {
  padding: 2px 8px;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 500;
  transition: 0.5s ease;
}

.pagination_wrap ul li:last-child {
  margin-right: 0;
}

.pagination_wrap ul li:hover {
  background-color: #885b24;
  color: #fff;
  cursor: pointer;
  transition: 0.5s ease;
}

.pagination_wrap ul li.active {
  background-color: #885b24;
  color: #fff;
}
.mcxtable_wrap table tbody tr td:nth-child(2) > div,
.mcxtable_wrap table tbody tr td:nth-child(3) > div,
.mcxtable_wrap table tbody tr td:nth-child(4) > div {
  min-width: 100px;
}
.mcxtable_wrap table tbody tr td:nth-child(5) > div {
  min-width: 150px;
}
.marketwatch_top_form {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 0.5rem;
}
.input_group_wrap {
  margin-bottom: 0;
  position: relative;
}
.input_group_wrap .text-error {
  position: absolute;
  left: 0;
  bottom: -18px;
}
.authority_limit_wrapper table thead tr {
  position: sticky;
  top: 0;
}
.disableViewButton {
  opacity: 0.6;
  pointer-events: none;
}

.user_dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  color: #fff;
  margin-left: 20px;
  border-radius: 5px;
  font-size: 0;
  padding: 5px 15px;
  position: relative;
}
.user_dropdown p {
  font-size: 14px;
  line-height: 17px;
  color: #fff;
}
.user_dropdown p span {
  display: block;
}
.user_dropdown i {
  color: #fff;
  padding: 0 13px 0 10px;
  display: flex;
}
.user_dropdown .view_only_text {
  color: #fff;
  background: #b6884d;
  font-size: 8px;
  line-height: 10px;
  font-weight: 700;
  position: absolute;
  padding: 5px 12px;
  top: 3px;
  right: -14px;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}
.tv_dropdown {
  padding-top: 6px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tv_dropdown .material-symbols-outlined {
  font-size: 28px;
}
.tv_dropdown_wrapper {
  position: relative;
}

.tv_dropdown_wrapper .tv_video_box {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 99;
  display: none;
}
.tv_dropdown_wrapper .tv_video_box.show {
  display: block;
}
.tv_dropdown_wrapper .tv_video_box .video_close_icon {
  cursor: pointer;
}
.tv_dropdown_wrapper .tv_video_box .video_close_icon span {
  color: #000;
  background: #fff;
  border-radius: 50%;
  padding: 6px;
  font-size: 16px;
  margin-bottom: 7px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

.login_wrapper {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
  }
}
.login_wrapper video {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.login_wrapper .card {
  max-width: 470px;
  width: 100%;
  margin: 0 15px;
}
.nifty_wrapper {
  display: flex;
  align-items: center;
}

.nifty_wrapper .nifty_box {
  padding: 9px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  margin-right: 10px;
  min-width: 250px;
  background: #fff;
}
.nifty_wrapper .nifty_box:last-child {
  margin-right: 0;
}
.nifty_wrapper .nifty_box p {
  margin: 0;
  font-size: 13px;
  font-weight: 400;
  text-align: right;
  line-height: 20px;
}
.nifty_wrapper .nifty_box h5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  /*  font-weight: 500;
  font-size: 15px;
  color: #000; */
}
.marketwatch_top_form {
  padding-left: 0;
  padding-right: 0;
}

// .mcxtable_wrap .table-responsive {
//   max-height: 710px;
// }
.mcxtable_wrap table thead tr th {
  position: sticky;
  top: 0;
}

.custom-control.custom-radio.left_space {
  margin-left: 20px;
  margin-bottom: 4px;
  margin-top: 4px;
}

.card_bold.table tbody tr td {
  font-weight: 700;
}

.table td {
  border-left: 1px solid #e9ecef !important;
}

.limits_custom_radio_wrapper .custom-control.custom-radio.left_space {
  margin-left: 20px;
}

.radio_wrapper.limits_custom_radio_wrapper
  .custom-control.custom-radio.left_space:first-child {
  margin-left: 0;
}
.privacy_policy_card {
  overflow: auto;
  max-height: 810px;
}

.cash_entry_form label.form-control-label {
  margin-bottom: 4px;
}

.custom_col_padding .custom_col {
  padding: 0 5px;
}

.upline_mobile_btn {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff0808;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

.upline_mobile_btn img {
  width: 26px;
}

.mobile_nav .nav-link {
  padding-left: 0;
  padding-right: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile_nav .dropdown {
  padding: 0 5px !important;
}

@media (max-width: 1681px) {
  .nifty_wrapper .nifty_box {
    min-width: 170px;
  }
}
@media (max-width: 1440px) {
  .custom-control.custom-radio.left_space {
    margin-left: 20px;
  }
  .header_nav {
    .contact_upline_btn {
      padding: 6px 10px;
      font-size: 12px;
      min-height: 30px;
    }
    h3 {
      font-size: 14px;
    }
    .nav-link {
      padding-left: 10px;
      padding-right: 10px;
    }
    .user_dropdown {
      margin-left: 10px;
      p {
        font-size: 12px;
        line-height: 16px;
      }
      i {
        padding: 0 8px 0 8px;
      }
    }
    .header_golden_icon {
      width: 30px !important;
      height: 30px !important;
    }
  }
}
@media (max-width: 1367px) {
  .nifty_wrapper .nifty_box {
    min-width: 150px;
  }
}
@media (max-width: 1200px) {
  .radio_wrapper {
    margin-top: 20px;
  }
  .limits_custom_radio_wrapper.radio_wrapper {
    margin-top: 0;
  }
  .radio_wrapper .custom-control.custom-radio.left_space:first-child {
    margin-left: 0;
  }
  .top-header.container-fluid {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .header_nav .contact_upline_btn {
    padding: 5px 6px;
    font-size: 11px;
    min-height: 30px;
  }
  .header_nav .header_golden_icon {
    width: 24px !important;
    height: 24px !important;
  }
}

@media (max-width: 767px) {
  .radio_wrapper {
    margin-bottom: 15px;
  }
  #subbrokercode {
    margin-top: 10px;
  }
  .custom-control.custom-radio.left_space {
    margin-left: 0;
    margin-right: 20px;
  }
}

@media (max-width: 991px) {
  .page_title_wrap {
    display: none;
  }
  .marquee_wrapper {
    order: 3;
  }
  .marquee_wrapper marquee {
    margin: 10px 0 0;
  }
  .main-content {
    margin-top: 120px;
  }
  .top-header.nav-close {
    padding-bottom: 5px !important;
  }
  .limits_custom_radio_wrapper {
    width: 100%;
  }
}

@media (max-width: 767.98px) {
  .navbar-vertical.navbar-expand-md .navbar-collapse {
    height: 100vh !important;
    width: 100%;
    margin: 0;
    max-width: 300px;
    background: #b6884d;
    padding: 15px;
    border-radius: 0;
    display: block !important;
    opacity: 1;
    left: -100%;
    transition: 0.5s ease;
    z-index: 999;
    overflow: auto;
  }
  .navbar-vertical.navbar-expand-md .navbar-collapse.show {
    left: 0;
  }
  .navbar-vertical.navbar-expand-md .navbar-collapse.show:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0);
    z-index: -1;
  }
  .navbar-collapse .collapse-close {
    position: absolute;
    right: 0;
    top: 19px;
  }
  .mobile_sidebar .collapse-brand a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile_sidebar .collapse-brand a h1 {
    color: #fff;
    font-size: 18px;
  }
  .navbar-collapse .navbar-collapse-header {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .main-content {
    margin-top: 96px;
  }
  .login_wrapper.app_login_wrapper:before {
    content: "";
    position: absolute;
    top: inherit;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: #885b24;
    z-index: -1;
  }
  .login_wrapper.app_login_wrapper:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 330px 767px;
    border-color: transparent transparent #b6884d transparent;
    z-index: -1;
  }
  .login_wrapper.app_login_wrapper video {
    display: none;
  }
}

.authority_limit_wrapper .table-responsive-y {
  max-height: 540px;
}

@media (max-width: 575px) {
  .btn-icon {
    margin-top: 15px;
  }
  .custom_col_padding .nifty_wrapper {
    display: block;
  }
}

.icon-img img {
  width: 30px;
}

@media (min-width: 1600px) {
  .qty_border {
    border-left: 1px solid #000;
  }
}

.custome_Scrollbar {
  div + div + div {
    background: #102d5b;
    div {
      background-color: #738db8 !important;
    }
  }
}
@media (max-width: 991px) {
  .positoin_total_wrap {
    h3 {
      font-size: 14px;
    }
  }
  .navbar .nav-item .nav-link-icon.nav-link i {
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .navbar .navbar-brand h1 {
    font-size: 14px;
  }
}

@media (max-width: 380px) {
  .navbar .navbar-brand h1 {
    font-size: 12px;
  }
}

.cutom_error_button {
  position: absolute;
  left: 0;
}

.modal .modal-dialog {
  background: transparent !important;
}

.lmh_trade {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media (max-width: 991px) {
    flex-direction: column;
    @media (max-width: 767px) {
      flex-direction: row;
      justify-content: flex-start;
    }
  }
}

.lmh_trade li {
  padding: 0 5px;
  display: flex;
  gap: 5px;
}

.top_dashboard_wrap .nav-scrollable::-webkit-scrollbar-thumb {
  background: #738db8bd;
  border-radius: 10px;
}

.top_dashboard_wrap .nav-scrollable {
  padding: 0px 4px 7px 4px;
  flex-wrap: wrap;
  margin-right: 0;
  @media (max-width: 575px) {
    flex-wrap: nowrap;
    margin-bottom: 10px;
  }
}

.top_dashboard_wrap .nav-scrollable li {
  margin-bottom: 10px;
  @media (max-width: 575px) {
    margin-bottom: 0;
  }
}

.top_dashboard_wrap .nav-scrollable::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

.top_dashboard_wrap .nav-pills .nav-link {
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 600;
}

.select_wrap {
  position: relative;
  background-image: url(../../img/icons/Chevron_Down.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: right 15px center;
  height: auto;
  padding: 10px;
  appearance: none;
}
.select_wrap:focus {
  background-image: url(../../img/icons/Chevron_Down1.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: right 15px center;
}
.select_wrap option {
  padding: 20px;
  background: #fff;
}
.trade-inspe-btn {
  // margin-top: 31px;
  @media (max-width: 1200px) {
    margin-top: 0;
  }
}

.cash_entry_form.card.success {
  background-color: #e4ffe4;
}
.cash_entry_form.card.danger {
  background-color: #ffe4e4;
}

.btn_wrap_row {
  @media (max-width: 480px) {
    display: block !important;
  }
}

.btn_submit_mt {
  @media (max-width: 1200px) {
    margin-top: 39px;
    @media (max-width: 991px) {
      margin-top: 15px;
      @media (max-width: 767px) {
        margin-top: 39px;
        @media (max-width: 575px) {
          margin-top: 15px;
        }
      }
    }
  }
}
.custom_dropdown .dropdown-menu.show {
  right: 0 !important;
  left: inherit !important;
  transform: inherit !important;
  top: 42px !important;
}
.sameIpTable {
  max-height: 600px;
  margin-bottom: 0;
}
.accept_btn {
  .btn {
    &:hover {
      color: #fff;
      background-color: #b6884d;
      border-color: #b6884d;
    }
  }
}

.warning_modal_wrapper {
  max-width: 90%;
  .modal-content {
    background: #f00;
    border: 3px solid #fff;
    .modal-body {
      padding: 40px;
      p {
        color: #fff;
        font-size: 20px;
        margin: 0 0 10px;
        font-weight: 600;
        line-height: 24px;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.custom_select {
  padding: 6px 8px;
  background-color: white;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: hsl(0, 0%, 20%);
  font-size: 0.875rem;
  width: 200px;
  option {
    font-size: 18px;
  }
}

.breadcrumb{
  margin-bottom: 0px;
}

.top_dashboard_card_main_wrap {
  width: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.top_dashboard_right {
  &.top_dashboard_card_main {
    display: flex;
    // flex-wrap: wrap;
    gap: 7px;
    overflow: auto;

    .card {
      @media (max-width: 767px) {
        min-width: auto;
      }
      .card-body {
        @media (max-width: 991px) {
          h1 {
            line-height: 22px;
            font-size: 16px;
            white-space: nowrap;
          }
          span {
            line-height: 22px;
            font-size: 16px;
            white-space: nowrap;
          }
        }
        @media (max-width: 480px) {
          h1 {
            line-height: 20px;
            font-size: 14px;
            white-space: nowrap;
          }
          span {
            line-height: 20px;
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

.minimize_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 40px;
  width: 40px;
  max-width: 40px;
  border-radius: 50%;
  transition: 0.3s ease;
  .inner_icon {
    font-size: 30px;
    line-height: 30px;
    transition: 0.3s ease;
  }
  &.expand_more {
    .inner_icon {
      transform: rotate(180deg);
      transition: 0.3s ease;
    }
  }
}

@media (max-width: 575px) {
  .minimize_btn {
    height: 30px;
    width: 30px;
    max-width: 40px;
    min-width: 40px;
    .inner_icon {
      font-size: 25px;
      line-height: 27px;
    }
  }
  .home_dashboard_wrap {
    .card-header {
      padding-left: 10px;
      padding-right: 10px;
      .btn {
        font-size: 12px;
      }
      h3 {
        font-size: 15px;
      }
    }
  }
  .top_dashboard_right.top_dashboard_card_main .card .card-body span {
    line-height: 20px;
    font-size: 14px;
    white-space: nowrap;
  }
}

@media (max-width: 400px) {
  .home_dashboard_wrap {
    .card-header {
      .minimize_btn {
        margin-left: 0;
      }
      .btn {
        font-size: 11px;
        padding: 10px 8px;
      }
    }
  }
}
