.mobile_header {
  background: #885b24;
  padding: 0 15px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.innerHeader {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.innerHeader > div {
  // padding: 0 10px;
  color: #fff;
  font-size: 18px;
  // width: 35px;
  line-height: 20px;
}

body {
  padding-top: 0;
}

img {
  max-width: 100%;
}

.user_icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  padding: 0 !important;
}

.user_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobile_sidebar_menu {
  position: fixed;
  left: -100%;
  top: 0;
  background: #fff;
  width: 100% !important;
  max-width: 300px;
  height: 100vh;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  z-index: 99;
  transition: 0.5s ease;
}

.mobile_sidebar_menu.show {
  left: 0;
}

.mobile_sidebar_menu .sidebar_menu_header {
  background: #f7f7f7;
  padding: 18px 20px;
  color: #885b24;
}

.sidebar_menu_header .close_icon {
  display: flex;
  align-items: center;
}

.close_icon span {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  margin-right: 10px;
}

.sidebar_menu_body {
  padding: 75px 15px;
}

.userName h4 {
  text-align: center;
  margin: 0 0 20px;
}

.sidebar_menu_body ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar_menu_body ul li a {
  font-size: 14px;
  color: #000;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  margin: 0 0 10px;
}
.mobile_logout {
  font-size: 14px;
  color: #000;
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  margin: 0 0 10px;
}

.searchBar {
  position: relative;
}

.searchBar span {
  position: absolute;
  top: 11px;
  left: 10px;
  color: #000;
  font-weight: bold;
  font-size: 19px;
}

.chackLebleInput{
  ul{
    li{
      label{
        margin: 0;
      }
    }
  }
}

.searchBar .form-control {
  width: 100%;
  padding: 10px 10px 10px 40px;
}

.material-symbols-outlined {
  font-weight: 600;
}

.top_tab_wrap {
  padding: 10px 15px;
  position: fixed;
  top: 94px;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.top_tab_wrap ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.top_tab_wrap ul::-webkit-scrollbar {
  display: none;
}
.top_tab_wrap ul li {
  margin: 0 5px;
}
.top_tab_wrap ul li:first-child {
  margin-left: 0;
}
.top_tab_wrap ul li {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: #8b8b8b;
  border-radius: 30px;
  border: 1px solid #8b8b8b;
  padding: 7px 12px;
  display: inline-block;
  line-height: 14px;
}

.top_tab_wrap ul .active {
  background-color: #a8b1e438;
  color: #885b24;
  border: 1px solid #885b24;
}

.market_watch_table_row {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.market_watch_table_row .remove_row {
  position: absolute;
  right: 0;
  top: -2px;
  color: #f5365c;
  z-index: 9;
}
.market_watch_table_row p {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  // text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #000;
}

.spa_fw {
  font-weight: 400;
}

/* .market_watch_table_row .row */
.disable_row {
  opacity: 0.6;
  pointer-events: none;
}

/* .market_watch_table_row p span {
  color: #666;
} */

.market_watch_table_row h5 {
  margin: 0;
}

.market_watch_table_row .row > div {
  margin-bottom: 3px;
}

.market_watch_table_row p span.material-symbols-outlined {
  color: #f5365c;
  line-height: 18px;
}

/* .market_watch_table_row .down {
  color: #f5365c;
} */
/* .market_watch_table_row .up {
  color: #0aab66;
} */
.page_titme {
  width: 20%;
  text-align: left;
}
.footer_mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #e1faff;
}

.footer_mobile ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_mobile ul a {
  width: 25%;
}
.footer_mobile ul a {
  color: #fff;
  padding: 10px 4px;
  text-align: center;
  font-size: 11px;
  display: block;
  background: #885b24;
}

.footer_mobile ul a.active {
  color: #666;
  background: transparent;
}

.footer_mobile ul a i {
  font-size: 20px;
}

.footer_mobile ul a span {
  display: block;
}
.order_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.page_titme h1 {
  font-size: 15px;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
}

.position_page .search_mobile {
  padding-left: 15px;
}
// .order_page .search_mobile,
// .trade_page .search_mobile {
//   margin-bottom: 10px;
// }

.innerHeader .search_mobile {
  padding-right: 15px;
}
.trade_page .order_right_icon {
  width: 11%;
}

.order_right_icon {
  display: flex;
  align-items: center;
  color: #fff;
  width: 18%;
  justify-content: flex-end;
}
.user_profile_header .order_header {
  padding: 15px 0;
}
.order_header .user_icon {
  padding: 0 !important;
  width: 25%;
  border: 0;
  height: auto;
  border-radius: 0;
}
.order_header .user_icon img {
  object-fit: cover;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
}
.order_right_icon > div {
  padding: 0 5px;
}
.order_right_icon > div:last-child {
  padding-right: 0;
}

.order_page .top_tab_wrap {
  top: inherit;
  position: inherit;
}

.order_page .main_content_wrap {
  padding: 0 15px 0;
  height: calc(100vh - 335px);
}
.order_page .orderBook_tabs .nav-pills {
  top: 65px;
}

// .orderBook_tabs {
//   position: fixed;
//   top: 104px;
//   left: 0;
//   width: 100%;
// }
.orderBook_tabs .nav-pills {
  margin: 0 !important;
  position: fixed;
  top: 104px;
  width: 100%;
  left: 0;
  background: #fff;
}
.orderBook_tabs .tab-content {
  margin-top: 100px;
}
.orderBook_tabs .nav-pills li {
  width: 50%;
}

.orderBook_tabs .nav-pills .nav-link {
  margin: 0 auto;
  background: transparent;
  box-shadow: none;
  padding: 7px 0;
  color: #666;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  width: 100%;
}
.orderBook_tabs .nav-pills .nav-link:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: #885b24;
  content: "";
}

.orderBook_tabs .nav-pills .nav-link.active {
  color: #885b24;
}
.orderBook_tabs .nav-pills .nav-link.active:before {
  width: 100%;
}

.green_bedge {
  background: #43d98738;
  color: #007c38;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 7px;
  border-radius: 5px;
}

.red_bedge {
  background: #f5365c1f;
  color: #f5365c;
  font-size: 12px;
  font-weight: 600;
  padding: 3px 7px;
  border-radius: 5px;
}

.market_watch_table_row .time_status_wrap span.material-symbols-outlined {
  font-size: 26px;
}

.time_status_wrap {
  display: flex;
  justify-content: flex-end;
}

.status_pending {
  color: #f5365c !important;
  padding: 0 5px;
  text-transform: capitalize;
}
.status_Completed {
  color: #007c38 !important;
  padding: 0 5px;
  text-transform: capitalize;
}

.market_watch_table_row .time_status_wrap span.material-symbols-outlined.time {
  color: #666;
  font-size: 18px;
}
.trade_page .main_content_wrap {
  padding: 65px 0 65px;
}

.trade_page .main_content_wrap #custom_table_observer_1 {
  padding: 0 15px;
}

.ModalBody {
  padding: 20px;
  position: relative;
}
.ModalBody .close_btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.ModalBody h4 {
  text-align: center;
  font-size: 18px;
  margin: 0 0 15px;
}
.ModalBody .addScript_ul ul {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalBody .addScript_ul ul li {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: #8b8b8b;
  border-radius: 30px;
  border: 1px solid #8b8b8b;
  padding: 7px 12px;
  display: inline-block;
  line-height: 14px;
  margin: 0 5px;
}
.add_script_select {
  width: 100%;
  padding: 0 5px;
  margin-bottom: 25px;
  position: relative;
}

.dashboard_home {
  padding: 20px 10px;
}

.add_script_select .text-error {
  font-size: 11px;
  color: #f5365c;
  position: absolute;
  bottom: -16px;
  left: 7px;
}

.position_page .orderBook_tabs {
  height: calc(100vh - 244px);
}
.position_page .orderBook_tabs .nav-pills {
  top: 109px;
}
.position_page .orderBook_tabs .tab-content {
  margin-top: 144px;
}
.position_type {
  position: fixed;
  top: 59px;
  left: 0;
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.position_page .top_tab_wrap {
  position: relative;
  top: inherit;
}

.position_page .main_content_wrap {
  padding: 0px 15px 40px;
  height: calc(100vh - 294px);
  overflow: auto;
}

.user_page .page_titme {
  width: 100%;
  padding: 5px 0;
}

.refresh_btn .btn-default {
  font-size: 12px;
  padding: 6px 10px;
  min-height: auto;
  line-height: 16px;
}

.info_wrap span {
  color: #666 !important;
}

.info_wrap span.close_icon {
  color: #f5365c !important;
}

.rollover_wrap {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  background: #fff;
  z-index: 999;
}

.rollover_wrap .rollover_btn .btn-default {
  padding: 5px 10px;
  min-height: auto;
  font-size: 12px;
}
.total_wrap p {
  margin: 0;
  font-size: 13px;
  font-weight: 600;
}

.total_wrap p span.down {
  color: #f5365c;
}

.total_wrap p span.up {
  color: #0aab66;
}

.add_script_modal.modal-dialog-centered {
  align-items: flex-end;
  margin: 0;
  min-height: 100%;
}
.add_script_modal .ModalBody {
  padding: 45px 20px 20px;
}
.order_confirm_order {
  padding-top: 53px;
}
.buy_sell_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.buy_sell_btn .btn-icon {
  font-size: 15px;
  padding: 8px 40px;
  width: 47%;
  border: 0;
  border-radius: 3px;
}
.buy_sell_btn .btn_buy {
  background: #005c10;
  text-align: center;
  color: #fff;
  font-weight: 600;
}
.buy_sell_btn .btn_sell {
  background: #e50000;
  text-align: center;
  color: #fff;
  font-weight: 600;
}
.confirm_order_header {
  background: var(--blue);
  color: #fff;
  padding: 15px 10px;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}
.confirm_order_header .close_icon {
  display: flex;
  align-items: center;
}
.radio_wrap_order {
  display: flex;
}
.radio_wrap_order {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;
}
.trade_report_main_wrap .radio_wrap_order {
  padding: 10px 0;
}
.confirm_main_wrap {
  padding: 20px 10px;
}
.confirm_input_wrap_inner {
  position: relative;
  margin: 0 0 5px;
  min-height: 60px;
}
.confirm_input_wrap_inner .form-control {
  background: transparent;
  border-radius: 0;
  border: 1px solid #e3e3e3;
  padding: 10px 20px 10px 45px;
}
.confirm_input_wrap_inner label {
  position: absolute;
  left: 10px;
  top: 9px;
  font-size: 14px;
  margin: 0;
}
.confirm_input_wrap_inner.price .form-control {
  padding: 10px 20px 10px 55px;
}

.confirm_input_wrap_inner .form-control:disabled,
.confirm_input_wrap_inner .form-control[readonly] {
  background-color: #dbedff !important;
  opacity: 1;
}
.confirm_main_wrap .buy_sell_btn {
  flex-direction: column;
}
.confirm_main_wrap .buy_sell_btn .btn-icon {
  width: 100%;
  margin: 0;
}
.confirm_input_wrap {
  margin: 0 0 20px;
}
.change_password_wrap {
  padding: 20px 10px;
}
.change_password_wrap .form-group {
  position: relative;
}
.change_password_wrap .form-group .text-error {
  position: absolute;
  bottom: -19px;
  left: 0;
}
.change_password_wrap .btn-default {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px);
  background: #885b24;
  border: 0;
  border-radius: 3px;
}
.confirm_order_header .close_icon span {
  text-transform: capitalize;
  letter-spacing: 0;
}
.banned_script_wrap {
  padding: 10px 10px;
  background: #fff;
}
.banned_script_wrap .banned_script_inner {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
.banned_script_inner ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.banned_script_inner ul li {
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-bottom: 5px;
}
.banned_script_inner ul li span {
  text-transform: capitalize;
  color: #999;
  font-weight: 400;
}
.rules_Wrap {
  padding: 10px 0;
}
.rules_Wrap h2 {
  background-color: #dcf0ff;
  font-size: 17px;
  padding: 10px;
  margin: 0 0 10px !important;
}
.rules_Wrap_inner {
  padding: 10px;
}
.rules_Wrap_inner ul {
  padding-left: 20px;
}
.rules_Wrap_inner ul li {
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 5px;
}
.rules_Wrap_inner p {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
}
.dashboard_home marquee {
  position: fixed;
  top: 64px;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 99;
  padding: 5px 0;
}
.trade_report_main_wrap .radio_wrap_order {
  justify-content: flex-start;
}
.trade_resport_body {
  padding: 0 10px 20px 10px;
}
.trade_report_button {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px);
}
.trade_report_button .btn_black {
  background: #000;
  color: #fff;
  border: 0;
  box-shadow: none;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 14px;
  text-transform: uppercase;
  width: 47%;
}
.share_resport {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px;
}
.trade_report_button .btn_blue {
  background: #885b24;
  color: #fff;
  border: 0;
  box-shadow: none;
  padding: 10px 40px;
  border-radius: 3px;
  font-size: 14px;
  text-transform: uppercase;
  width: 100%;
}
.ledger_report_table table {
  width: 100%;
}
.ledger_report_table table thead tr th {
  background: #b4b7bd;
  font-size: 13px;
  padding: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--blue);
  letter-spacing: 0;
  font-weight: 600;
}
.ledger_report_table table tbody tr td {
  font-size: 11px;
  font-weight: 600;
  padding: 8px 10px;
  border-bottom: 1px solid #ddd;
}
.market_up_down {
  padding-right: 30px;
}
.ledger_report_table .table_responisve{
  height: calc(100vh - 55px);
}
.ledger_report_table  table thead tr th{
  position: sticky;
  top: 0;
  z-index: 99;
}
@media (max-width: 767.98px) {
  body {
    padding-top: 0 !important;
    overflow-x: hidden;
  }
  #toast_custom {
    width: 96% !important;
    height: auto !important;
    white-space: normal !important;
    align-items: center;
    display: flex;
  }
  #toast_custom #desc {
    height: auto !important;
    white-space: normal !important;
    width: calc(100% - 50px);
    font-size: 14px;
    padding-left: 0 !important;
  }
}
.errorMessage {
  color: red;
  font-size: 13px;
  margin-left: 10px;
  font-weight: 550;
}

.filter_select {
  position: relative;
  margin-bottom: 15px;
}
.filter_select .text-error {
  font-size: 11px;
  color: #f5365c;
  position: absolute;
  bottom: -18px;
  left: 2px;
}

// .orderBook_tabs .tab-content .table-responsive {
//   max-height: inherit !important;
// }

.market_watch_table_wrap #custom_table_observer {
  overflow-x: hidden;
  padding: 0 15px;
  max-height: calc(100vh - 218px) !important;
  margin-bottom: 0;
}

.trade_page #custom_table_observer_1 {
  max-height: calc(100vh - 133px) !important;
  margin-bottom: 0;
  overflow-x: hidden;
}

.order_page .orderBook_tabs .main_content_wrap {
  height: auto;
  overflow: hidden;
  padding: 0;
}

.client_detail {
  padding: 10px 10px 0;
}

.client_detail p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}
.submit_button .btn_submit {
  width: 100%;
  background: #885b24;
  border: 0;
  border-radius: 4px;
}
.time_status_wrap {
  padding-right: 20px;
}
.remove_row_icon {
  position: absolute;
  right: -1px;
  top: -2px;
  z-index: 9;
}
.remove_row_icon span {
  color: #f5365c;
}
.close_position_wrap {
  padding: 10px;
}
.banned_script_wrap_inner {
  left: 0;
  width: 100%;
  padding: 10px 15px;
}
.banned_script_wrap_inner .radio_wrap {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.add_script_wrap {
  min-height: 100vh;
}

.market_watch_main .main_content_wrap {
  height: calc(100vh - 80px);
  overflow-y: auto;
  padding: 130px 10px 0;
  overflow-x: hidden;
  background-color: #000;
}
.dashboard_home.market_watch_main {
  padding: 20px 0px;
}

.position_summary {
  padding: 0;
  margin: 20px 0 0;
  list-style: none;
}
.position_summary li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
  font-size: 14px;
  font-weight: 600;
}

.position_summary li:last-child {
  margin: 0;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.market_watch_main .top_tab_wrap {
  top: 153px;
}
.market_watch_main .nifty_wrapper {
  position: fixed;
  top: 65px;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  padding: 5px;
}
.market_watch_main .dashboard_home marquee {
  top: 125px;
}
.market_watch_main .main_content_wrap {
  height: calc(100vh - 80px);
  padding: 200px 10px 0;
}
.dashboard_home.market_watch_main marquee {
  top: 123px;
}
.dashboard_home.market_watch_main .nifty_wrapper .nifty_box {
  min-width: auto;
}

.footer_mobile ul a span:last-child {
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 72px;
  margin: 0 auto;
}
.nifty_wrapper .nifty_box p {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
